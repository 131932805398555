<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-tabs type="card">
          <a-tab-pane key="1" tab="基础/设备信息">
          <!-- ==========================基础信息======================= -->
            <h3 style="font-weight: bold; color: #333;">基础信息</h3>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="formDatas.liftnum" />
            </a-form-model-item>
            <a-form-model-item label="救援码" prop="liftrescue">
              <a-input v-model.trim="formDatas.liftrescue" />
            </a-form-model-item>
            <a-form-model-item label="注册登记机构" prop="registerdep">
              <a-input v-model.trim="formDatas.registerdep" />
            </a-form-model-item>
            <a-form-model-item label="注册登记时间" prop="registertime">
              <a-date-picker v-model="registertimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="注册更新时间" prop="registeruptime">
              <a-date-picker v-model="registeruptimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="内部编号" prop="liftcode">
              <a-input v-model.trim="formDatas.liftcode" />
            </a-form-model-item>
            <a-form-model-item label="出厂编号" prop="factorynum">
              <a-input v-model.trim="formDatas.factorynum" />
            </a-form-model-item>
            <a-form-model-item label="厂车牌照编号" prop="factorylicense">
              <a-input v-model.trim="formDatas.factorylicense" />
            </a-form-model-item>
            <a-form-model-item label="操作人员" prop="operator">
              <a-input v-model.trim="formDatas.operator" />
            </a-form-model-item>
            <a-form-model-item label="区域" prop="areacode">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="formDatas.adress" />
            </a-form-model-item>
            <a-form-model-item label="坐标" prop="coordinatex">
              <a-input v-model.trim="formDatas.coordinatex" style="width: 30%" />
              <a-input v-model.trim="formDatas.coordinatey" style="width: 30%; margin-left: 5%" />
              <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectCoordinateVisible=true">选择</a-button>
            </a-form-model-item>
            <a-form-model-item label="监管单位" prop="adminuserdepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="adminuserdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择监管单位" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="使用状态" prop="status">
              <a-radio-group v-model="formDatas.status">
                <a-radio :value="1">使用</a-radio>
                <a-radio :value="0">未使用</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="使用场所" prop="placeid">
              <a-select v-model="formDatas.placeid">
                <a-select-option v-for="(item, index) in placeidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯重要等级" prop="level">
              <a-select v-model="formDatas.level">
                <a-select-option v-for="(item, index) in levelOptions" :key="index" :value="item.code">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- =======================设备信息======================= -->
            <h3 style="font-weight: bold; color: #333;">设备信息</h3>
            <a-form-model-item label="用户设备类别" prop="devicetypeid">
              <a-select v-model="formDatas.devicetypeid">
                <a-select-option v-for="(item, index) in devicetypeidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="用户设备型号" prop="devicemodelid">
              <a-input :value="deviceModelObj?deviceModelObj.value:''" style="width: 65%;" disabled />
              <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectDevicemodelVisible=true">选择</a-button>
            </a-form-model-item>
            <a-form-model-item label="电梯品牌" prop="brandid">
              <a-select v-model="formDatas.brandid">
                <a-select-option v-for="(item, index) in brandidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备许可证" prop="devicelicense">
              <a-input v-model.trim="formDatas.devicelicense" />
            </a-form-model-item>
            <a-form-model-item label="许可证有效期" prop="devicelicstarttime">
              <a-range-picker v-model="devicelictimeRange"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="许可证级别" prop="devicelicenselevel">
              <a-input v-model.trim="formDatas.devicelicenselevel" />
            </a-form-model-item>
            <a-form-model-item label="拖动方式" prop="dragtype">
              <a-select v-model="formDatas.dragtype">
                <a-select-option v-for="(item, index) in dragtypeOptions" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="最高楼层" prop="floornum">
              <a-input v-model.trim="formDatas.floornum" />
            </a-form-model-item>
            <a-form-model-item label="站数" prop="stepnum">
              <a-input v-model.trim="formDatas.stepnum" />
            </a-form-model-item>
            <a-form-model-item label="门数" prop="doornum">
              <a-input v-model.trim="formDatas.doornum" />
            </a-form-model-item>
            <a-form-model-item label="额定速度" prop="speed">
              <a-input v-model.trim="formDatas.speed" />
            </a-form-model-item>
            <a-form-model-item label="载重量(kg)" prop="weight">
              <a-input v-model.trim="formDatas.weight" />
            </a-form-model-item>
            <h3 style="font-weight: bold; color: #333;">设备使用标志和登记信息证件查看</h3>
            <a-form-model-item label="使用登记编号" prop="regesternum">
              <a-input v-model.trim="formDatas.regesternum" />
            </a-form-model-item>
            <a-form-model-item label="使用标志证" prop="userflagurl">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="userflagurlList" @change="userflagurlUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="使用登记证" prop="userloginurl">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="userloginurlList" @change="userloginurlUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
          </a-tab-pane>
          <a-tab-pane key="2" tab="管理信息">
            <!-- ========================使用单位信息============== -->
            <h3 style="font-weight: bold; color: #333;">使用单位信息</h3>
            <a-form-model-item label="使用单位" prop="userdepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="负责人" prop="userdeppreson">
              <a-input v-model.trim="formDatas.userdeppreson" />
            </a-form-model-item>
            <a-form-model-item label="负责人电话" prop="userdeppersonphone">
              <a-input v-model.trim="formDatas.userdeppersonphone" :max-length="11" />
            </a-form-model-item>
            <a-form-model-item label="地产品牌" prop="estatemodelid">
              <a-select v-model="formDatas.estatemodelid">
                <a-select-option v-for="(item, index) in estatemodelidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- =======================产权单位信息======================= -->
            <h3 style="font-weight: bold; color: #333;">产权单位信息</h3>
            <a-form-model-item label="产权单位名称" prop="propertydep">
              <a-input v-model.trim="formDatas.propertydep" />
            </a-form-model-item>
            <a-form-model-item label="产权单位代码" prop="propertydepnum">
              <a-input v-model.trim="formDatas.propertydepnum" />
            </a-form-model-item>
            <a-form-model-item label="产权单位地址" prop="propertydepadr">
              <a-input v-model.trim="formDatas.propertydepadr" />
            </a-form-model-item>
            <a-form-model-item label="邮政编码" prop="propertydeppost">
              <a-input v-model.trim="formDatas.propertydeppost" />
            </a-form-model-item>
            <a-form-model-item label="产权单位法人" prop="propertydepperson">
              <a-input v-model.trim="formDatas.propertydepperson" />
            </a-form-model-item>
            <a-form-model-item label="产权单位负责人" prop="propertydepadmin">
              <a-input v-model.trim="formDatas.propertydepadmin" />
            </a-form-model-item>
            <a-form-model-item label="电话" prop="propertydepphone">
              <a-input v-model.trim="formDatas.propertydepphone" :max-length="11" />
            </a-form-model-item>
            <a-form-model-item label="安装日期" prop="installtime">
              <a-date-picker v-model="installtimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="使用日期" prop="usetime">
              <a-date-picker v-model="usetimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <!-- =======================设备维保信息====================== -->
            <h3 style="font-weight: bold; color: #333;">设备维保信息</h3>
            <a-form-model-item label="维保单位" prop="maintenancedepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维保单位" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="维保类型" prop="maintenancetype">
              <a-select v-model="formDatas.maintenancetype">
                <a-select-option v-for="(item, index) in maintenancetypeOptions" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="维保组" prop="maintenancegroupid">
              <a-select v-model="formDatas.maintenancegroupid">
                <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="大修/改造日期" prop="bigrepairtime">
              <a-date-picker v-model="bigrepairtimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="大修周期(周)" prop="bigrepaircycle">
              <a-input v-model.trim="formDatas.bigrepaircycle" />
            </a-form-model-item>
            <!-- =====================检验信息==================== -->
            <h3 style="font-weight: bold; color: #333;">检验信息</h3>
            <a-form-model-item label="检验单位" prop="inspectiondepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="inspectiondepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择检验单位" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <h3 style="font-weight: bold; color: #333;">维保合同信息</h3>
            <a-form-model-item label="合同上传地址" prop="maintenancecontacturl">
              <a-input v-model.trim="formDatas.maintenancecontacturl" />
            </a-form-model-item>
            <a-form-model-item label="合同有效期" prop="maintenancecontacttime">
              <a-date-picker v-model="maintenancecontacttimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <h3 style="font-weight: bold; color: #333;">IP语音网关和可视通讯</h3>
            <a-form-model-item label="IP语音网关分机号" prop="ipnetnum">
              <a-input v-model.trim="formDatas.ipnetnum" />
            </a-form-model-item>
            <a-form-model-item label="视频对讲机号" prop="videonum">
              <a-input v-model.trim="formDatas.videonum" />
            </a-form-model-item>
            <h3 style="font-weight: bold; color: #333;">保险信息</h3>
            <a-form-model-item label="保险单位" prop="insurancedep">
              <a-input v-model.trim="formDatas.insurancedep" />
            </a-form-model-item>
            <a-form-model-item label="保险单号" prop="insurancenum">
              <a-input v-model.trim="formDatas.insurancenum" />
            </a-form-model-item>
            <a-form-model-item label="理赔电话" prop="insurancephone">
              <a-input v-model.trim="formDatas.insurancephone" />
            </a-form-model-item>
            <a-form-model-item label="理赔有效期" prop="insuranceexpiretime">
              <a-date-picker v-model="insuranceexpiretimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
          </a-tab-pane>
          <a-tab-pane key="3" tab="设备安装信息">
            <h3 style="font-weight: bold; color: #333;">设备安装信息</h3>
            <a-form-model-item label="安装单位" prop="installdep">
              <a-input v-model.trim="formDatas.installdep" />
            </a-form-model-item>
            <a-form-model-item label="安装单位编码" prop="installdepnum">
              <a-input v-model.trim="formDatas.installdepnum" />
            </a-form-model-item>
            <a-form-model-item label="安装证书编码" prop="installlicnum">
              <a-input v-model.trim="formDatas.installlicnum" />
            </a-form-model-item>
            <a-form-model-item label="安装负责人" prop="installadmin">
              <a-input v-model.trim="formDatas.installadmin" />
            </a-form-model-item>
            <a-form-model-item label="安装负责人电话" prop="installadminphone">
              <a-input v-model.trim="formDatas.installadminphone" />
            </a-form-model-item>
            <a-form-model-item label="安装施工单位" prop="constructiondep">
              <a-input v-model.trim="formDatas.constructiondep" />
            </a-form-model-item>
            <a-form-model-item label="施工日期" prop="constructiontime">
              <a-date-picker v-model="constructiontimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="验收单位" prop="acceptdep">
              <a-input v-model.trim="formDatas.acceptdep" />
            </a-form-model-item>
            <a-form-model-item label="验收日期" prop="checktime">
              <a-date-picker v-model="checktimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="验收报告编号" prop="checkreportnum">
              <a-input v-model.trim="formDatas.checkreportnum" />
            </a-form-model-item>
            <h3 style="font-weight: bold; color: #333;">设计制造信息</h3>
            <a-form-model-item label="设计单位" prop="designdep">
              <a-input v-model.trim="formDatas.designdep" />
            </a-form-model-item>
            <a-form-model-item label="设计单位编码" prop="designdepnum">
              <a-input v-model.trim="formDatas.designdepnum" />
            </a-form-model-item>
            <a-form-model-item label="设备制造商" prop="manufacturerid">
              <a-select v-model="formDatas.manufacturerid">
                <a-select-option v-for="(item, index) in manufactureridOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="资格证" prop="manufacturerlic">
              <a-input v-model.trim="formDatas.manufacturerlic" />
            </a-form-model-item>
            <a-form-model-item label="生产许可证" prop="productlic">
              <a-input v-model.trim="formDatas.productlic" />
            </a-form-model-item>
            <a-form-model-item label="生产编号" prop="productnum">
              <a-input v-model.trim="formDatas.productnum" />
            </a-form-model-item>
            <a-form-model-item label="生产日期" prop="producttime">
              <a-date-picker v-model="producttimeMoment" style="width: 100%;"></a-date-picker>
            </a-form-model-item>
          </a-tab-pane>
          <a-tab-pane key="4" tab="监测设备信息">
            <h3 style="font-weight: bold; color: #333;">监测设备信息</h3>
            <a-form-model-item label="关联设备" prop="deviceid">
              <a-input v-model.trim="formDatas.deviceid" />
            </a-form-model-item>
          </a-tab-pane>
        </a-tabs>
      </a-form-model>
    </a-modal>
    <select-coordinate :visible.sync="selectCoordinateVisible" @get-coordinate="getCoordinate"></select-coordinate>
    <select-devicemodel select-type="radio" :visible.sync="selectDevicemodelVisible" :default-selected="deviceModelObj?[deviceModelObj]:[]" @get-selected-rows="getDevicemodelSelectRows"></select-devicemodel>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { getTimeRange } from 'U'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import SelectDevicemodel from 'C/modals/SelectDevicemodel.vue'
import { getLiftListByCondition, getLiftinfoById, addLiftinfo, modifyLiftinfo } from 'A/jcgn'
import { getDictByDicType, getMentenacegroupListByCondition } from 'A/xtpz'
export default {
  mixins: [areaselect, deptselect],
  components: {
    SelectCoordinate,
    SelectDevicemodel,
  },
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      selectCoordinateVisible: false,
      selectDevicemodelVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        // 基础信息
        liftid: '',
        liftnum: '',
        liftrescue: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adress: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        placeid: '',
        level: '',
        // 设备信息
        devicetypeid: '',
        devicemodelid: '',
        brandid: '',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdepid: '',
        userdeppreson: '',
        userdeppersonphone: '',
        estatemodelid: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedepid: '',
        maintenancetype: '',
        maintenancegroupid: '',
        // 检验信息
        inspectiondepid: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '0',
        // 关联设备
        deviceid: '',
      },
      registertimeMoment: null,
      registeruptimeMoment: null,
      formRules: {
        liftnum: [{required: true, message: '请输入电梯注册码'}],
        liftrescue: [{required: true, message: '请输入救援码'}],
        registerdep: [{required: true, message: '请输入注册登记机构'}],
        areacode: [{required: true, message: '请选择区域'}],
        adress: [{required: true, message: '请输入安装地址'}],
        adminuserdepid: [{required: true, message: '请选择监管单位'}],
        placeid: [{required: true, message: '请选择使用场合'}],
        level: [{required: true, message: '请选择电梯重要等级'}],
        devicetypeid: [{required: true, message: '请选择设备类别'}],
        userdepid: [{required: true, message: '请选择使用单位'}],
        userdeppreson: [{required: true, message: '请输入负责人'}],
        maintenancedepid: [{required: true, message: '请选择维保单位'}],
        maintenancegroupid: [{required: true, message: '请选择维保组'}],
        inspectiondepid: [{required: true, message: '请选择检验单位'}],
        bigrepairtime: [{required: true, message: '请选择大修/改造日期'}],
        // userdepid: [{required: true, message: '请选择救援单位'}],
      },
      placeidOptions: [],
      levelOptions: [
        {
          code: 0,
          name: '高'
        },
        {
          code: 1,
          name: '一般'
        },
      ],
      devicetypeidOptions: [],
      deviceModelObj: null,
      brandidOptions: [],
      devicelictimeRange: null,
      dragtypeOptions: [],
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      inspectiondepidCascaderSelected: [],
      adminuserdepidCascaderSelected: [],
      estatemodelidOptions: [],
      maintenancetypeOptions: [],
      maintenancegroupidOptions: [],
      installtimeMoment: null,
      usetimeMoment: null,
      bigrepairtimeMoment: null,
      insuranceexpiretimeMoment: null,
      constructiontimeMoment: null,
      checktimeMoment: null,
      producttimeMoment: null,
      maintenancecontacttimeMoment: null,
      manufactureridOptions: [],
      userflagurlList: [],
      userloginurlList: [],
    }
  },
  computed: {
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      return '电梯详情'
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    registertimeMoment(val) {
      if(val) {
        this.formDatas.registertime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registertime')
      }else {
        this.formDatas.registertime = '';
      }
    },
    registeruptimeMoment(val) {
      if(val) {
        this.formDatas.registeruptime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registeruptime')
      }else {
        this.formDatas.registeruptime = '';
      }
    },
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    deviceModelObj(val) {
      if(val) {
        this.formDatas.devicemodelid = val.liftpropertyid;
      }else {
        this.formDatas.devicemodelid = ''
      }
    },
    devicelictimeRange(val) {
      let {start, end} = getTimeRange(val);
      this.formDatas.devicelicstarttime = start;
      this.formDatas.devicelicendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
      }else {
        this.formDatas.userdepid = '';
      }
    },
    adminuserdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.adminuserdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('adminuserdepid')
      }else {
        this.formDatas.adminuserdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid')
        this.initMaintenancegroupidOptions();
      }else {
        this.formDatas.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
    inspectiondepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.inspectiondepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('inspectiondepid')
      }else {
        this.formDatas.inspectiondepid = '';
      }
    },
    installtimeMoment(val) {
      if(val) {
        this.formDatas.installtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('installtime')
      }else {
        this.formDatas.installtime = '';
      }
    },
    usetimeMoment(val) {
      if(val) {
        this.formDatas.usetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('usetime')
      }else {
        this.formDatas.usetime = '';
      }
    },
    bigrepairtimeMoment(val) {
      if(val) {
        this.formDatas.bigrepairtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('bigrepairtime')
      }else {
        this.formDatas.bigrepairtime = '';
      }
    },
    insuranceexpiretimeMoment(val) {
      if(val) {
        this.formDatas.insuranceexpiretime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('insuranceexpiretime')
      }else {
        this.formDatas.insuranceexpiretime = '';
      }
    },
    constructiontimeMoment(val) {
      if(val) {
        this.formDatas.constructiontime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('constructiontime')
      }else {
        this.formDatas.constructiontime = '';
      }
    },
    checktimeMoment(val) {
      if(val) {
        this.formDatas.checktime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('checktime')
      }else {
        this.formDatas.checktime = '';
      }
    },
    producttimeMoment(val) {
      if(val) {
        this.formDatas.producttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('producttime')
      }else {
        this.formDatas.producttime = '';
      }
    },
    maintenancecontacttimeMoment(val) {
      if(val) {
        this.formDatas.maintenancecontacttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('maintenancecontacttime')
      }else {
        this.formDatas.maintenancecontacttime = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initPlaceidOptions();
    this.initDevicetypeidOptions();
    this.initBrandidOptions();
    this.initDragtypeOptions();
    this.initEstatemodelidOptions();
    this.initMaintenancetypeOptions();
    // this.initMaintenancegroupidOptions();
    this.initManufactureridOptions();
  },
  methods: {
    initPlaceidOptions() {
      let params = {
        liftpropertytype: '4',
        pageno: 1,
        pagesize: 500
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.placeidOptions = res.item;
        }
      })
    },
    initDevicetypeidOptions() {
      let params = {
        liftpropertytype: '1',
        pageno: 1,
        pagesize: 500
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.devicetypeidOptions = res.item;
        }
      })
    },
    initBrandidOptions() {
      let params = {
        liftpropertytype: '3',
        pageno: 1,
        pagesize: 500
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.brandidOptions = res.item;
        }
      })
    },
    initDragtypeOptions() {
      let params = {
        dictype: '13',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.dragtypeOptions = res.item;
        }
      })
    },
    initEstatemodelidOptions() {
      let params = {
        liftpropertytype: '5',
        pageno: 1,
        pagesize: 500
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.estatemodelidOptions = res.item;
        }
      })
    },
    initMaintenancetypeOptions() {
      let params = {
        dictype: '14',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancetypeOptions = res.item;
        }
      })
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.formDatas.maintenancedepid,
        pageno: 1,
        pagesize: 500
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    initManufactureridOptions() {
      let params = {
        liftpropertytype: '6',
        pageno: 1,
        pagesize: 500
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.manufactureridOptions = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData && this.detailData.liftid) {
        this.$nextTick(() => {
          let params = {
            liftid: this.detailData.liftid
          }
          getLiftinfoById(params).then(res => {
            if(res && res.returncode == '0') {
              for(let key in this.formDatas) {
                if(res.item[key] || res.item[key] === 0) {
                  this.formDatas[key] = res.item[key];
                }
              }
              // 特别数据回显
              if(res.item.registertime) {
                this.registertimeMoment = moment(res.item.registertime, 'YYYYMMDD')
              }
              if(res.item.registeruptime) {
                this.registeruptimeMoment = moment(res.item.registeruptime, 'YYYYMMDD')
              }
              if(res.item.devicemodelid) {
                this.deviceModelObj = {
                  liftpropertyid: res.item.devicemodelid,
                  value: res.item.devicemodelname || 'testdevicemodel',
                }
              }
              if(res.item.areacode) {
                this.initAreaOptionsByAreacode(res.item.areacode)
              }
              if(res.item.adminuserdepid) {
                this.initDeptOptionsByUserdepid(res.item.adminuserdepid, 'adminuserdepidCascaderSelected')
              }
              if(res.item.devicelicstarttime && res.item.devicelicendtime) {
                this.devicelictimeRange = [moment(res.item.devicelicstarttime, 'YYYYMMDD'), moment(res.item.devicelicendtime, 'YYYYMMDD')]
              }
              if(res.item.userdepid) {
                this.initDeptOptionsByUserdepid(res.item.userdepid, 'userdepidCascaderSelected')
              }
              if(res.item.maintenancedepid) {
                this.initDeptOptionsByUserdepid(res.item.maintenancedepid, 'maintenancedepidCascaderSelected')
              }
              if(res.item.inspectiondepid) {
                this.initDeptOptionsByUserdepid(res.item.inspectiondepid, 'inspectiondepidCascaderSelected')
              }
              if(res.item.installtime) {
                this.installtimeMoment = moment(res.item.installtime, 'YYYYMMDD')
              }
              if(res.item.usetime) {
                this.usetimeMoment = moment(res.item.usetime, 'YYYYMMDD')
              }
              if(res.item.bigrepairtime) {
                this.bigrepairtimeMoment = moment(res.item.bigrepairtime, 'YYYYMMDD')
              }
              if(res.item.insuranceexpiretime) {
                this.insuranceexpiretimeMoment = moment(res.item.insuranceexpiretime, 'YYYYMMDD')
              }
              if(res.item.constructiontime) {
                this.constructiontimeMoment = moment(res.item.constructiontime, 'YYYYMMDD')
              }
              if(res.item.checktime) {
                this.checktimeMoment = moment(res.item.checktime, 'YYYYMMDD')
              }
              if(res.item.producttime) {
                this.producttimeMoment = moment(res.item.producttime, 'YYYYMMDD')
              }
              if(res.item.maintenancecontacttime) {
                this.maintenancecontacttimeMoment = moment(res.item.maintenancecontacttime, 'YYYYMMDD')
              }
              if(res.item.userflagurl) {
                let nameIndex = res.item.userflagurl.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = res.item.userflagurl.lastIndexOf('\\');
                }
                let fileName = res.item.userflagurl.substr(nameIndex+1);
                this.userflagurlList = [{
                  uid: 'userflagurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userflagurl,
                }];
              }
              if(res.item.userloginurl) {
                let nameIndex = res.item.userloginurl.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = res.item.userloginurl.lastIndexOf('\\');
                }
                let fileName = res.item.userloginurl.substr(nameIndex+1);
                this.userloginurlList = [{
                  uid: 'userloginurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userloginurl,
                }];
              }
            }
          })
        })
      }
    },
    resetDatas() {
      this.formDatas.liftid = '';
      this.registertimeMoment = null;
      this.registeruptimeMoment = null;
      this.areaCascaderSelected = [];
      this.deviceModelObj = null;
      this.devicelictimeRange = null;
      this.userdepidCascaderSelected = [];
      this.adminuserdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.inspectiondepidCascaderSelected = [];
      this.installtimeMoment = null;
      this.usetimeMoment = null;
      this.bigrepairtimeMoment = null;
      this.insuranceexpiretimeMoment = null;
      this.constructiontimeMoment = null;
      this.checktimeMoment = null;
      this.producttimeMoment = null;
      this.maintenancecontacttimeMoment = null;
      this.$refs.modalForm.resetFields();
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
    getDevicemodelSelectRows(rows) {
      this.deviceModelObj = rows[0]
    },
    userflagurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userflagurl = file.response.urlPath;
        }
        return file;
      });

      this.userflagurlList = fileList;
    },
    userloginurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userloginurl = file.response.urlPath;
        }
        return file;
      });

      this.userloginurlList = fileList;
    },
  }
}
</script>