<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="modalVisible=false">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formDatas" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="工单信息">
          <h3 style="font-weight: bold; color: #333;">工单信息</h3>
          <a-form-model-item label="任务单号" prop="maintenancordernum">
            <span>{{formDatas.maintenancordernum}}</span>
          </a-form-model-item>
          <a-form-model-item label="电梯编码" prop="liftnum">
            <span>{{formDatas.liftnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="电梯救援码" prop="liftrescue">
            <span>{{formDatas.liftrescue}}</span>
          </a-form-model-item>
          <a-form-model-item label="安装地址" prop="adress">
            <span>{{formDatas.adress}}</span>
          </a-form-model-item>
          <a-form-model-item label="电梯品牌" prop="brandname">
            <span>{{formDatas.brandname}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备型号" prop="devicemodelname">
            <span>{{formDatas.devicemodelname}}</span>
          </a-form-model-item>
          <a-form-model-item label="生产时间" prop="creattime">
            <span>{{creattimeShow}}</span>
          </a-form-model-item>
          <!-- <a-form-model-item label="应完成日期" prop="needfinishtime">
            <span>{{needfinishtimeShow}}</span>
          </a-form-model-item> -->
          <a-form-model-item label="维修类型" prop="maintenanctype">
            <span>{{formDatas.maintenanctype == '2' ? '困人急修' : '普通维修'}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修内容" prop="content">
            <span>{{formDatas.content}}</span>
          </a-form-model-item>
          <a-form-model-item label="故障描述" prop="orderdecs">
            <span>{{formDatas.orderdecs}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保单位" prop="maintenancedepname">
            <span>{{formDatas.maintenancedepname}}</span>
          </a-form-model-item>
          <a-form-model-item label="状态" prop="orderstatus">
            <span>{{orderstatusMap[formDatas.orderstatus]||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="流程节点" prop="processstep">
            <span>{{orderstepsMap[formDatas.processstep]||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="困人事件描述" prop="passengertrappeddesc">
            <span>{{formDatas.passengertrappeddesc}}</span>
          </a-form-model-item>
          <a-form-model-item label="困人数量" prop="passengertrappednum">
            <span>{{formDatas.passengertrappednum}}</span>
          </a-form-model-item>
          <a-form-model-item label="困人回访" prop="passengertrappedvisi">
            <span>{{formDatas.passengertrappedvisi}}</span>
          </a-form-model-item>
          <a-form-model-item label="困人回访人电话" prop="passengertrappedvisipho">
            <span>{{formDatas.passengertrappedvisipho}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">派单信息</h3>
          <a-form-model-item label="派单人员" prop="dispatchperson">
            <span>{{formDatas.dispatchperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="派单时间" prop="dispatchtime">
            <span>{{formDatas.dispatchtime ? moment(formDatas.dispatchtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单人员" prop="takeperson">
            <span>{{formDatas.takeperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单时间" prop="taketime">
            <span>{{formDatas.taketime ? moment(formDatas.taketime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到人员" prop="registerperson">
            <span>{{formDatas.registerperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到时间" prop="registertime">
            <span>{{formDatas.registertime ? moment(formDatas.registertime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到地点" prop="signinaddress">
            <span>{{formDatas.signinaddress}}</span>
          </a-form-model-item>
          <a-form-model-item label="检修开始时间" prop="checkstarttime">
            <span>{{formDatas.checkstarttime ? moment(formDatas.checkstarttime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="检修结束时间" prop="checkendtime">
            <span>{{formDatas.checkendtime ? moment(formDatas.checkendtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保结论" prop="resultcode">
            <span>{{resultcodeMap[formDatas.resultcode]}}</span>
          </a-form-model-item>
          <a-form-model-item label="故障原因" prop="fault">
            <span>{{formDatas.fault}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保说明" prop="resultdecs">
            <span>{{formDatas.resultdecs}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">评价信息</h3>
          <a-form-model-item label="组长评价" class="nobg" prop="groupmanauditgrade">
            <a-rate :default-value="formDatas.groupmanauditgrade" v-model="formDatas.groupmanauditgrade" disabled />
          </a-form-model-item>
          <a-form-model-item label="物业评价" class="nobg" prop="userauditgrade">
            <a-rate :default-value="formDatas.userauditgrade" v-model="formDatas.userauditgrade" disabled />
          </a-form-model-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="检查项目">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenanitemrecordid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="index" slot-scope="value, record, index">
              {{index+1}}
            </span>
            <span slot="maintenanitemstatus" slot-scope="value">
              {{maintenanceitemStatusMap[value]}}
            </span>
          </a-table>
          <h3 style="margin-top: 20px; font-weight: bold;">零部件列表</h3>
          <a-table :columns="partTableColumns" :data-source="partTableData" row-key="tableKey" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="index" slot-scope="value, record, index">
              {{index+1}}
            </span>
          </a-table>
          <div class="picture-list">
            <div class="picture-line" v-for="(item, index) in cameraList" :key="index">
              <h3>{{item.pictypeName}}：</h3>
              <div v-if="item.pictype == '16' || item.pictype == '22'" style="overflow: hidden;padding-bottom: 8px;">
                <video v-for="(cItem, cIndex) in item.list" :key="cIndex" :src="cItem.url" controls="controls"></video>
              </div>
              <a-upload v-else list-type="picture-card" :file-list="item.list" disabled></a-upload>
              <!-- <img v-else :src="item.picurl" :alt="item.pictypeName"> -->
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="工单追踪">
          <a-timeline>
            <a-timeline-item v-for="(item, index) in wrokflow" :key="index" :color="index<wrokflowInex?'green':'gray'">
              <div>操作步骤: {{orderstepsMap[item.status]}}</div>
              <div>操作人员: {{item.person}}</div>
              <div>完成时间: {{item.finishtime}}</div>
            </a-timeline-item>
          </a-timeline>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
import orderstepsMap from '@/json/ordersteps'
import maintenanceitemStatusMap from '@/json/maintenanceitemStatusMap'
import maintenancepictypeMap from '@/json/maintenancepictypeMap'
import orderstatusMap from '@/json/orderstatusMap'
import resultcodeMap from '@/json/resultcodeMap'
import { findWorkflowByorderId, findMaintenanceitemrecordListByOrderId, findMaintenancepicByOrderId, getMaintenanceorderInfoByorderId } from 'A/wbgl.js'
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      orderstepsMap,
      maintenanceitemStatusMap,
      maintenancepictypeMap,
      orderstatusMap,
      resultcodeMap,
      modalVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      formDatas: {
        maintenanceorderid: '',
        maintenancordernum: '',
        liftnum: '',
        liftrescue: '',
        adress: '',
        brandname: '',
        devicemodelname: '',
        creattime: '',
        needfinishtime: '',
        maintenanctype: '',
        content: '',
        orderdecs: '',
        maintenancedepname: '',
        orderstatus: '',
        processstep: '',
        passengertrappeddesc: '',
        passengertrappednum: '',
        passengertrappedvisi: '',
        passengertrappedvisipho: '',
        userauditgrade: 0,
        groupmanauditgrade: 0,

        dispatchperson: '',
        dispatchtime: '',
        takeperson: '',
        taketime: '',
        registerperson: '',
        registertime: '',
        signinaddress: '',
        checkstarttime: '',
        checkendtime: '',
        resultcode: '',
        fault: '',
        resultdecs: '',
      },
      tableColumns: [
        {
          title: '序号',
          key: 'index',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '项目名称',
          dataIndex: 'itemname',
          key: 'itemname',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'maintenanitemstatus',
          key: 'maintenanitemstatus',
          width: 100,
          scopedSlots: { customRender: 'maintenanitemstatus' }
        },
        {
          title: '检查结果',
          dataIndex: 'resultdesc',
          key: 'resultdesc',
          ellipsis: true,
        },
      ],
      tableData: [],
      partTableColumns: [
        {
          title: '序号',
          key: 'index',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '零部件名称',
          dataIndex: 'liftpartname',
          key: 'liftpartname',
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'liftpartcount',
          key: 'liftpartcount',
          width: 100,
          align: 'center',
        },
        {
          title: '描述',
          dataIndex: 'liftpartdesc',
          key: 'liftpartdesc',
          ellipsis: true,
        },
      ],
      partTableData: [],
      // pictureList: [],
      cameraList: [],
      wrokflow: [],
      wrokflowInex: 0,
    }
  },
  computed: {
    modalTitle() {
      return '详情'
    },
    creattimeShow() {
      if(this.formDatas.creattime) {
        return moment(this.formDatas.creattime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      }else {
        return '';
      }
    },
    needfinishtimeShow() {
      if(this.formDatas.needfinishtime) {
        return moment(this.formDatas.needfinishtime, 'YYYYMMDD').format('YYYY-MM-DD')
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getTableData();
        this.getPictureList();
        this.getWorkFlow();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && this.detailData.maintenancorderid) {
        this.$nextTick(() => {
          let params = {
            maintenancorderid: this.detailData.maintenancorderid
          }
          getMaintenanceorderInfoByorderId(params).then(res => {
            if(res && res.returncode == '0') {
              for(let key in this.formDatas) {
                if('userauditgrade'==key||'groupmanauditgrade'==key) {
                  this.formDatas[key] = Number(res.item[key]||0)
                }else {
                  this.formDatas[key] = res.item[key]
                }
              }
              if(res.item.liftpartList && res.item.liftpartList.length) {
                this.partTableData = res.item.liftpartList.map((item, index) => {
                  item.tableKey='tableKey'+index;
                  return item;
                });
              }
              // this.formDatas = {
              //   ...this.formDatas,
              //   ...res.item
              // };
              this.getWorkFlowIndex();
            }
          })
        })
      }
    },
    resetDatas() {
      this.formDatas.maintenanceorderid = '';
      this.tableData = [];
      this.partTableData = [];
      // this.pictureList = [];
      this.cameraList = [];
      this.$refs.modalForm.resetFields();
    },
    getWorkFlow() {
      let params = {
        maintenancorderid: this.detailData.maintenancorderid
      };
      findWorkflowByorderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.wrokflow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formDatas.processstep && this.wrokflow && this.wrokflow.length) {
        this.wrokflow.forEach((item, index) => {
          if(item.status == this.formDatas.processstep) {
            this.wrokflowInex = index;
          }
        })
      }
    },
    getTableData() {
      let params = {
        maintenanceorderid: this.detailData.maintenancorderid
      };
      findMaintenanceitemrecordListByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
        }
      })
    },
    getPictureList() {
      let params = {
        maintenanceorderid: this.detailData.maintenancorderid
      };
      findMaintenancepicByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          // this.pictureList = res.item.map(item => {
          //   let nameIndex = item.picurl.lastIndexOf('/');
          //   if(nameIndex < 0) {
          //     nameIndex = item.picurl.lastIndexOf('\\');
          //   }
          //   if(nameIndex > -1) {
          //     item.picname = item.picurl.substr(nameIndex+1);
          //   }
          //   item.pictypeName = maintenancepictypeMap[item.pictype] || '';
          //   return item;
          // });
          let cameraListMap = {};
          res.item.forEach((item, index) => {
            if(!cameraListMap[item.pictype]) {
              cameraListMap[item.pictype] = [];
            }
            let obj = {
              ...item,
              uid: 'uid-'+item.pictype+'-'+index,
              status: 'done',
              url: item.picurl
            };
            let nameIndex = item.picurl.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = item.picurl.lastIndexOf('\\');
            }
            if(nameIndex > -1) {
              obj.name = item.picurl.substr(nameIndex+1);
            }
            obj.pictypeName = maintenancepictypeMap[item.pictype] || '';
            cameraListMap[item.pictype].push(obj);
          })
          for(let key in cameraListMap) {
            this.cameraList.push({
              pictype: cameraListMap[key][0].pictype,
              pictypeName: cameraListMap[key][0].pictypeName,
              list: cameraListMap[key]
            })
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.picture-list {
  padding-top: 5px;
  .picture-line {
    margin-top: 10px;
    // border-top: solid 1px #e8e8e8;
  }
  video {
    float: left;
    width: 104px;
    margin-right: 8px;
  }
}
</style>