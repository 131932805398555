<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1280" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">关闭</a-button>
      <a-button type="primary" v-if="!taskNum" @click="confirm">确认</a-button>
    </template>
    <h1 style="text-align: center">电梯检验申请/受理单</h1>
    <a-form-model ref="modalForm" layout="inline" :model="formDatas" :rules="formRules" class="form-in-twocolumns" :label-col="{span:8}" :wrapper-col="{span:16}">
      <a-form-model-item label="使用单位" prop="userdepname">
        <a-input v-model.trim="formDatas.userdepname" />
      </a-form-model-item>
      <a-form-model-item label="统一社会信用代码" prop="creditcode">
        <a-input v-model.trim="formDatas.creditcode" />
      </a-form-model-item>
      <a-form-model-item label="使用单位联系人" prop="userdeplinkman">
        <a-input v-model.trim="formDatas.userdeplinkman" />
      </a-form-model-item>
      <a-form-model-item label="使用单位联系电话" prop="userdeplinkphone">
        <a-input v-model.trim="formDatas.userdeplinkphone" :max-length="11"/>
      </a-form-model-item>
      <a-form-model-item label="传真" prop="fax">
        <a-input v-model.trim="formDatas.fax" />
      </a-form-model-item>
      <a-form-model-item label="使用单位详细地址" prop="address">
        <a-input v-model.trim="formDatas.address" />
      </a-form-model-item>
      <a-form-model-item label="应急救援电话" prop="rescuephone">
        <a-input v-model.trim="formDatas.rescuephone" :max-length="11"/>
      </a-form-model-item>
      <a-form-model-item label="维修保养单位" prop="maintaindepname">
        <a-input v-model.trim="formDatas.maintaindepname" />
      </a-form-model-item>
      <a-form-model-item label="维保单位联系人" prop="maintaindeplinkman">
        <a-input v-model.trim="formDatas.maintaindeplinkman" />
      </a-form-model-item>
      <a-form-model-item label="维保单位联系电话" prop="maintaindeplinkphone">
        <a-input v-model.trim="formDatas.maintaindeplinkphone" :max-length="11"/>
      </a-form-model-item>
      <a-form-model-item label="申请检验性质" prop="nature">
        <a-radio-group name="nature" v-model="formDatas.nature" @change="nature">
          <a-radio :value="1">
            定期检验
          </a-radio>
          <a-radio :value="2">
            委托检验
          </a-radio>
          <a-radio :value="3">
            安全评估
          </a-radio>
          <a-radio :value="4">
            电梯检测
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="检验日期" prop="inspectiondate">
        <a-date-picker style="width: 100%;" v-model="formDatas.inspectiondate" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="dateChange"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="检验报告交付方式" prop="deliverytype">
        <a-radio-group name="deliveryType" v-model="formDatas.deliverytype" @change="deliveryType">
          <a-radio :value="1">
            快递
          </a-radio>
          <a-radio :value="2">
            自取
          </a-radio>
          <a-radio :value="3">
            其他
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="检验报告接收方信息">
        <a-input addon-before="收件人" v-model.trim="formDatas.receiver" style="width: 200px"/>
        <a-input addon-before="电话" v-model.trim="formDatas.receivephone" :max-length="11" style="width: 200px"/>
        <a-input addon-before="地址" v-model.trim="formDatas.receiveaddress" style="width: 400px"/>
      </a-form-model-item>
    </a-form-model>
    <h2 class="retract">设备清单（数量超过13台的，将清单作为附件）<a-button type="primary" class="editable-add-btn" v-if="!taskNum" @click="handleAdd">新增</a-button></h2>
    <div class="retract" style="width: 1150px">
      <a-table bordered :data-source="dataSource" row-key="num" :pagination="false" :columns="columns">
        <template slot="liftnum" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'liftnum', $event)" />
        </template>
        <template slot="lifttype" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'lifttype', $event)" />
        </template>
        <template slot="floornum" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'floornum', $event)" />
        </template>
        <template slot="speed" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'speed', $event)" />
        </template>
        <template slot="money" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'money', $event)" />
        </template>
        <template slot="address" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'address', $event)" />
        </template>
        <template slot="refernum" slot-scope="text, record">
          <editable-cell :text="text" @change="onCellChange(record.num, 'refernum', $event)" />
        </template>
<!--        <template slot="operation" slot-scope="text, record">-->
<!--          <a-popconfirm-->
<!--              v-if="dataSource.length"-->
<!--              title="Sure to delete?"-->
<!--              @confirm="() => onDelete(record.key)"-->
<!--          >-->
<!--            <a href="javascript:;">Delete</a>-->
<!--          </a-popconfirm>-->
<!--        </template>-->
      </a-table>
    </div>
    <a-form-model ref="modalForm" layout="inline" :model="formDatas" :rules="formRules" class="form-in-twocolumns" :label-col="{span:8}" :wrapper-col="{span:16}">
      <a-form-model-item label="小写金额合计" prop="lowermoney">
        <a-input v-model.trim="formDatas.lowermoney" addon-after="元"/>
      </a-form-model-item>
      <a-form-model-item label="大写金额合计" prop="uppermoney">
        <div v-if="!taskNum">
          <a-input v-model.trim="uppermoneyList.tenthousands" addon-after="万" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.thousands" addon-after="千" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.hundreds" addon-after="佰" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.ten" addon-after="拾" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.dollars" addon-after="元" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.angle" addon-after="角" style="width: 100px"/>
          <a-input v-model.trim="uppermoneyList.second" addon-after="分" style="width: 100px"/>
        </div>
        <div v-if="taskNum">
          <a-input v-model="formDatas.uppermoney"></a-input>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div><span class="retract">说明：</span></div>
    <div><span class="retract">1、设备安装详细地址填写，应明确设备所属区、街道办、工业区（小区）名、道路名、建筑物名等详细信息；</span></div>
    <div><span class="retract">2、电梯品种填写：（1）乘客电梯（2）载货电梯（3）自动扶梯（4）自动人行道（5）液压电梯（6）消防员电梯（7）杂物电梯</span></div>
    <div class="interval"><span class="retract">检验依据：</span>
      <a-radio-group name="checkGist" v-model="formDatas.checkgist" @change="checkGist">
        <a-radio :value="1">
          TSG T7001-2009
        </a-radio>
        <a-radio :value="2">
          TSG T7002-2009
        </a-radio>
        <a-radio :value="3">
          TSG T7004-2009
        </a-radio>
        <a-radio :value="4">
          TSG T7005-2012
        </a-radio>
        <a-radio :value="5">
          TSG T7006-2009
        </a-radio>
        <a-radio :value="6">
          TSG T5001-2009
        </a-radio>
        <a-radio :value="7">
          质检特函（2015）57号
        </a-radio>
      </a-radio-group>
    </div>
    <div class="interval"><span class="retract">费用支付方式：</span>
      <a-radio-group name="payType" v-model="formDatas.paytype" @change="payType">
        <a-radio :value="1">
          现金
        </a-radio>
        <a-radio :value="2">
          银行汇款
        </a-radio>
        <a-radio :value="3">
          网银支付
        </a-radio>
        <a-radio :value="4">
          POS机
        </a-radio>
        <a-radio :value="5">
          其他：<a-input ></a-input>
        </a-radio>
      </a-radio-group>
    </div>
    <div class="interval"><span class="retract">收款信息：</span>
      <a-form-model ref="modalForm" layout="inline" :model="formDatas" :rules="formRules" class="form-in-twocolumns" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="单位名称" prop="gatherdep">
          <a-input v-model.trim="formDatas.gatherdep" />
        </a-form-model-item>
        <a-form-model-item label="开户账号" prop="accountnum">
          <a-input v-model.trim="formDatas.accountnum" />
        </a-form-model-item>
        <a-form-model-item label="开户行" prop="accountbank">
          <a-input v-model.trim="formDatas.accountbank" />
        </a-form-model-item>
        <a-form-model-item label="行号" prop="banknum">
          <a-input v-model.trim="formDatas.banknum" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <h2 class="retract interval">申报资料清单</h2>
    <div class="retract" style="width: 1150px">
      <a-table :columns="columns_data" :data-source="filling" :pagination="false" bordered></a-table>
    </div>
    <div class="interval"><span class="retract">申请人对以上申请信息与受理信息进行确认 。</span></div>
    <div class="interval"><span class="retract">申请单位：（盖章）</span><span style="margin-left: 420px">受理单位：（盖章）</span></div>
    <div class="interval"><span class="retract">申请人签名：</span><span style="margin-left: 462px">受理人签名：</span></div>
    <div class="interval"><span class="retract">确认日期：</span><span style="margin-left: 476px">受理日期：</span></div>
    <a-form-model ref="modalForm" layout="inline" :model="queryForms" class="form-in-twocolumns" :label-col="{span:8}" :wrapper-col="{span:16}">
      <a-form-model-item label="受理电话" prop="acceptphone">
        <a-input v-model="queryForms.acceptphone" :max-length="11"/>
      </a-form-model-item>
      <a-form-model-item label="受理单位地址" prop="acceptaddress">
        <a-input v-model="queryForms.acceptaddress" />
      </a-form-model-item>
    </a-form-model>
    <div class="interval"><span class="retract">申明</span></div>
    <div><span class="retract">1.本单一式二联，第1联由检验机构存档，第2联交受检单位。</span></div>
    <div><span class="retract">2.检验报告一式三份，一份由检验机构存档，一份交受检单位，一份交维护保养单位。</span></div>
    <div><span class="retract">3.受检单位对报告结论如有异议，请在收到报告书之日起15日内，向检验机构提出书面意见。</span></div>
  </a-modal>
</template>
<script>
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
import moment from 'moment'
import {getCache} from "U/index";
import {
  addAcceptanceform,
  addAcceptancelift,
  addInspectionorder,
  findAcceptanceFormListByCondition,
  findAcceptanceLiftListByCondition
} from "A/jyjcgl";
export default {
  components: {
    EditableCell,
  },
  props: {
    visible: {
      default: false
    },
    taskNum:{
      default: ''
    },
    userDepId:{
      default: null
    },
    groupId:{
      default: null
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      useraccount:'',
      formDatas: {
        tasknum:'',
        userdepname: '',
        creditcode: '',
        userdeplinkman: '',
        userdeplinkphone: '',
        fax:'',
        address:'',
        rescuephone:'',
        maintaindepname:'',
        maintaindeplinkman:'',
        maintaindeplinkphone:'',
        nature:null,
        inspectiondate:null,
        deliverytype:null,
        receiver:'',
        receivephone:'',
        receiveaddress:'',
        lowermoney:'',
        uppermoney:'',
        checkgist:null,
        paytype:null,
        gatherdep:'',
        accountnum:'',
        accountbank:'',
        banknum:'',
        acceptphone:'',
        acceptaddress:'',
      },
      formRules: {
      },
      queryForms:{
        acceptphone:'',
        acceptaddress:'',
      },
      dataSource: [
        {
          num: 1,
          liftnum: '',
          lifttype: '',
          floornum:'',
          money:'',
          speed:'',
          refernum:'',
          address: '',
          tasktype:'',
          processperson:'',
        },
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'num',
        },
        {
          title: '设备代码',
          dataIndex: 'liftnum',
          scopedSlots: { customRender: 'liftnum' },
        },
        {
          title: '电梯品种',
          dataIndex: 'lifttype',
          scopedSlots: { customRender: 'lifttype' },
        },
        {
          title: '层站数(提升高度)',
          dataIndex: 'floornum',
          scopedSlots: { customRender: 'floornum' },
        },
        {
          title: '额定速度',
          dataIndex: 'speed',
          scopedSlots: { customRender: 'speed' },
        },
        {
          title: '费用(元)',
          dataIndex: 'money',
          scopedSlots: { customRender: 'money' },
        },
        {
          title: '设备安装详细地址',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '受理号',
          dataIndex: 'refernum',
          scopedSlots: { customRender: 'refernum' },
        },
      ],
      count: 2,
      columns_data:[
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '资料名称',
          dataIndex: 'file',
        },
        {
          title: '份数',
          dataIndex: 'parts',
        },
        {
          title: '备注',
          dataIndex: 'remark',
        },
      ],
      filling:[
        {
          key: '1',
          file: '电梯检验申请单',
          parts: '1',
          remark: '申请定期检验或复检时提供',
        },
        {
          key: '2',
          file: '电梯维修保养合同（复印件）',
          parts: '1',
          remark: '申请定期检验时提供原件，留存复印件',
        },
        {
          key: '3',
          file: '日常维护保养年度自行检查记录或者报告',
          parts: '1台/份',
          remark: '申请定期检验时提供原件',
        },
        {
          key: '4',
          file: '上一年度合格定期检验报告',
          parts: '1台/份',
          remark: '申请定期检验时提供',
        },
        {
          key: '5',
          file: '安全管理人员证（如有）',
          parts: '1',
          remark: '申请定期检验时提供复印件',
        },
        {
          key: '6',
          file: '检验意见书回复联及整改见证资料',
          parts: '1',
          remark: '申请复检时，整改确认时交检验人员',
        },
      ],
      uppermoneyList:{
        tenthousands:'',
        thousands:'',
        hundreds:'',
        ten:'',
        dollars:'',
        angle:'',
        second:'',
      },
    }
  },
  computed: {
    modalTitle() {
      return '新增'
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        if(this.taskNum){
          findAcceptanceFormListByCondition({tasknum:this.taskNum}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formDatas=res.item[0]
              this.queryForms.acceptphone=res.item[0].acceptphone
              this.queryForms.acceptaddress=res.item[0].acceptaddress
            }
          })
          this.dataSource=[];
          findAcceptanceLiftListByCondition({tasknum:this.taskNum}).then(res=>{
            if(res&&res.returncode=='0'){
              this.dataSource=res.item
            }
          })
        }
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.useraccount=logininfo.useraccount
    }
  },
  methods: {
    resetDatas() {
      this.formDatas={};
      this.queryForms={}
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      console.log(this.dataSource)
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          this.showLoading();
          this.formDatas.tasknum="CO" + moment(new Date).format("YYYYMMDDHHmmss")+ Math.round(Math.random()*9)+ Math.round(Math.random()*5)+ Math.round(Math.random()*5)+ Math.round(Math.random()*5);
          this.formDatas.uppermoney=(this.uppermoneyList.tenthousands?this.uppermoneyList.tenthousands+"万":"")+(this.uppermoneyList.thousands?this.uppermoneyList.thousands+"千":"")+(this.uppermoneyList.hundreds?this.uppermoneyList.hundreds+"佰":"")+(this.uppermoneyList.ten?this.uppermoneyList.ten+"拾":"")+(this.uppermoneyList.dollars?this.uppermoneyList.dollars+"元":"")+(this.uppermoneyList.angle?this.uppermoneyList.angle+"角":"")+(this.uppermoneyList.second?this.uppermoneyList.second+"分":"")
          this.formDatas.acceptphone=this.queryForms.acceptphone
          this.formDatas.acceptaddress=this.queryForms.acceptaddress
          let params = {
            userdepid:this.userDepId,
            groupid:this.groupId,
            tasktype:1,
            tasknum: this.formDatas.tasknum,
            needfinishtime:moment(this.formDatas.inspectiondate).format("YYYYMMDD"),
            processperson:this.useraccount,
            createraccount:this.useraccount,
          };
          addInspectionorder(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              addAcceptanceform(this.formDatas)
              console.log(this.dataSource)
              console.log()
              addAcceptancelift({tasknum:this.formDatas.tasknum,liftlist:this.dataSource})
              this.$message.success('操作成功');
              this.$emit('get-operation-result', 'success', '操作成功');
              this.modalVisible = false;
            }else {
              this.$message.error(res.errormsg||'操作失败');
            }
          })
        }else {
          return false;
        }
      })
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.num === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
        console.log(this.dataSource)
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.key !== key);
    },
    handleAdd() {
      const { count, dataSource } = this;
      if(count>13){
        this.$message.error("已超过数量上限，请勿再次添加")
        return
      }
      console.log(this.dataSource[0].num)
      const newData = {
        num: count,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    dateChange(date, dateString){
      console.log(date, dateString);
      this.formDatas.inspectiondate=dateString
    },
    checkGist(e){
      this.formDatas.checkgist=e.target.value
    },
    payType(e){
      this.formDatas.paytype=e.target.value
    },
    nature(e){
      this.formDatas.nature=e.target.value
    },
    deliveryType(e){
      this.formDatas.deliverytype=e.target.value
    }
  }
}
</script>
<style lang="scss" scoped>
.retract{
  margin-left: 70px;
}
.interval{
  margin-top: 3px
}
</style>