<template>
  <page-container title="检验受理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="任务单号" prop="tasknum">
              <a-input v-model.trim="queryParams.tasknum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="处理人账号" prop="processperson">
              <a-input v-model.trim="queryParams.processperson" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="节点状态" prop="processstep">
              <a-select v-model="queryParams.processstep">
                <a-select-option v-for="(item, index) in inspectionOrderstep" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="检验组" prop="inspectiongroupname">
              <a-input v-model="queryParams.inspectiongroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">检验任务</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="inspectionorderid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="tasknum" slot-scope="value">
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="needfinishtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="processstep" slot-scope="value">
            <a-tag :color="orderstepscolorMap[value]||''">{{orderstepsMap[value]||''}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.inspectionorderid">工单详情</a-menu-item>
                <a-menu-item :key="'delete-'+record.inspectionorderid">删除工单</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}/${counts}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="addCheckVisible" title="添加检验任务">
      <template slot="footer">
        <a-button @click="addCheckVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="queryParams" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item label="检验单位" prop="userdepid">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='4'?deptOptions.filter(item => item.deptype=='4'):deptOptionsAll.filter(item => item.deptype=='4')" v-model="inspectiondepidCascaderSelected" :load-data="userInfo.usertype=='4'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择检验单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="检验组" prop="groupid">
          <a-select v-model="queryParams.groupid">
            <a-select-option v-for="(item, index) in inspectiongroupidOptions" :key="index" :value="item.inspectiongroupid">{{item.inspectiongroupname}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <add-tasks :visible.sync="addModalVisible" :taskNum="tasknum" :userDepId="queryParams.userdepid" :groupId="queryParams.groupid" @get-operation-result="getTableData"></add-tasks>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
    <liftdetail-modal :visible.sync="liftdetailModalVisible" :detail-data="modalDetailData"></liftdetail-modal>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import inspectionOrderstep from '@/json/inspectionOrderstep'
import orderstepsMap from '@/json/ordersteps'
import orderstepscolorMap from '@/json/orderstepscolor'
import {getInspectiongroupListByCondition, getMentenacegroupListByCondition} from 'A/xtpz'
import DetailModal from './DetailModal'
import AddTasks from "V/checkanddetect/checkout/checktasks/AddTasks";
import LiftdetailModal from './LiftdetailModal'
import {cancelInspectionorder, deleteInspectionorder, findInspectionorderListByCondition} from "A/jyjcgl";
export default {
  name: 'repairTask',
  mixins: [deptselect, pagination],
  components: {
    AddTasks,
    DetailModal,
    LiftdetailModal,
  },
  data() {
    return {
      moment,
      tasknum:'',
      inspectionOrderstep,
      orderstepsMap,
      orderstepscolorMap,
      inspectiongroupidOptions: [],
      breadcrumb: [
        {
          name: '检验检测管理',
          path: ''
        },
        {
          name: '检验管理',
          path: ''
        },
        {
          name: '检验任务',
          path: ''
        },
      ],
      showAdvanced: false,
      queryParams: {
        userdepid:'',
        groupid:'',
        tasktype:1,
        tasknum:'',
        processperson: '',
        processstep: '',
        address: '',
        userdepname:'',
        maintenancedepname:'',
        inspectiongroupname:'',
      },
      formRules:[],
      userdepidCascaderSelected: [],
      inspectiondepidCascaderSelected: [],
      tableColumns: [
        {
          title: '任务单号',
          dataIndex: 'tasknum',
          key: 'tasknum',
          ellipsis: true,
          scopedSlots: { customRender: 'tasknum' }
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintaindepname',
          key: 'maintaindepname',
          ellipsis: true,
        },
        {
          title: '检验时间',
          dataIndex: 'needfinishtime',
          key: 'needfinishtime',
          ellipsis: true,
          scopedSlots: { customRender: 'needfinishtime' }
        },
        {
          title: '节点状态',
          dataIndex: 'processstep',
          key: 'processstep',
          ellipsis: true,
          scopedSlots: { customRender: 'processstep' }
        },
        {
          title: '操作',
          key: 'operation',
          width:80,
          align:'center',
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      addCheckVisible: false,
      addModalVisible: false,
      detailModalVisible: false,
      modalDetailData: null,
      liftdetailModalVisible: false,
      counts:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    // userdepidCascaderSelected(val) {
    //   if(val && val.length) {
    //     this.queryParams.userdepid = val[val.length-1];
    //   }else {
    //     this.queryParams.userdepid = '';
    //   }
    // },
    inspectiondepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        this.initInspectiongroupidOptions();
      }else {
        this.queryParams.userdepid = '';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.initDeptOptionsAll();
    },
    initInspectiongroupidOptions() {
      let params = {
        inspectiondepid: this.queryParams.inspectiondepid,
      }
      getInspectiongroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.inspectiongroupidOptions = res.item;
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.inspectiondepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if(params.userdepname==''&&params.maintenancedepname==''&&params.tasknum==''&&params.processperson==''&&params.processstep==''){
        findInspectionorderListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item.map(item => {
              return item;
            });
            this.counts = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
      findInspectionorderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            return item;
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    add() {
      this.addCheckVisible=true
    },
    confirm(){
      this.addCheckVisible=false;
      this.addModalVisible = true;
    },
    deleteConfirm(value, record) {
      this.$confirm({
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.inspectionorderid);
        },
        onCancel() {},
      });
    },
    delete(inspectionorderid) {
      this.showLoading();
      if(inspectionorderid) {
        let params = {
          inspectionorderid
        };
        deleteInspectionorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    cancelConfirm(value, record) {
      this.$confirm({
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.cancelOrder(record.inspectionorderid);
        },
        onCancel() {},
      });
    },
    cancelOrder(inspectionorderid) {
      this.showLoading();
      if(inspectionorderid) {
        let params = {
          inspectionorderid
        };
        cancelInspectionorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let inspectionorderid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'inspectionorderid', inspectionorderid);
      if(type == 'detail') {
        this.tasknum=record.tasknum
        this.addModalVisible=true
      } else if(type == 'delete') {
        this.deleteConfirm('', record);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>