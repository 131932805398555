// 检验检测管理
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 检验项目--添加
export function addInspectionitem(params) {
  return http({
    url: `${preApi}/inspection/addInspectionitem`,
    method: "post",
    data: params
  })
}
// 维保项目--修改
export function modifyInspectionitem(params) {
  return http({
    url: `${preApi}/inspection/modifyInspectionitem`,
    method: "post",
    data: params
  })
}
// 维保项目--删除
export function deleteInspectionitem(params) {
  return http({
    url: `${preApi}/inspection/deleteInspectionitem`,
    method: "post",
    data: params
  })
}
// 维保项目--列表
export function findInspectionitemListByCondition(params) {
  return http({
    url: `${preApi}/inspection/findInspectionitemListByCondition`,
    method: "post",
    data: params
  })
}
// 添加检验检测工单
export function addInspectionorder(params) {
  return http({
    url: `${preApi}/inspection/addInspectionorder`,
    method: "post",
    data: params
  })
}
// 添加检验检测申请表信息
export function addAcceptanceform(params) {
  return http({
    url: `${preApi}/inspection/addAcceptanceform`,
    method: "post",
    data: params
  })
}
// 添加检验检测电梯列表
export function addAcceptancelift(params) {
  return http({
    url: `${preApi}/inspection/addAcceptancelift`,
    method: "post",
    data: params
  })
}
// 删除检验检测工单
export function deleteInspectionorder(params) {
  return http({
    url: `${preApi}/inspection/deleteInspectionorder`,
    method: "post",
    data: params
  })
}
// 撤销检验检测工单
export function cancelInspectionorder(params) {
  return http({
    url: `${preApi}/maintenance/cancelInspectionorder`,
    method: "post",
    data: params
  })
}
// 查询工单信息列表
export function findInspectionorderListByCondition(params) {
  return http({
    url: `${preApi}/inspection/findInspectionorderListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单信息列表
export function findAcceptanceFormListByCondition(params) {
  return http({
    url: `${preApi}/inspection/findAcceptanceFormListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单信息列表
export function findAcceptanceLiftListByCondition(params) {
  return http({
    url: `${preApi}/inspection/findAcceptanceLiftListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单详情
export function getInspectionorderInfoByorderId(params) {
  return http({
    url: `${preApi}/maintenance/getInspectionorderInfoByorderId`,
    method: "post",
    data: params
  })
}
// 提交工单流程
export function commitWorkflow(params) {
  return http({
    url: `${preApi}/inspection/commitWorkflow`,
    method: "post",
    data: params
  })
}
// 查询工单流程
export function findWorkflowByorderId(params) {
  return http({
    url: `${preApi}/inspection/findWorkflowByorderId`,
    method: "post",
    data: params
  })
}
// 提交检验检测检查项信息
export function commitInspectionItemResult(params) {
  return http({
    url: `${preApi}/inspection/commitInspectionItemResult`,
    method: "post",
    data: params
  })
}
// 查询检验检测检查项信息
export function findInspectionitemrecordListByOrderId(params) {
  return http({
    url: `${preApi}/inspection/findInspectionitemrecordListByOrderId`,
    method: "post",
    data: params
  })
}
// 提交检验检测图片信息列表
export function commitInspectionpic(params) {
  return http({
    url: `${preApi}/inspection/commitInspectionpic`,
    method: "post",
    data: params
  })
}
// 查询检验检测图片信息列表
export function findInspectionpicByOrderId(params) {
  return http({
    url: `${preApi}/inspection/findInspectionpicByOrderId`,
    method: "post",
    data: params
  })
}
// // 检验检测项目--添加
// export function addInspectionitem(params) {
//   return http({
//     url: `${preApi}/maintenance/addInspectionitem`,
//     method: "post",
//     data: params
//   })
// }
// // 检验检测项目--修改
// export function modifyInspectionitem(params) {
//   return http({
//     url: `${preApi}/maintenance/modifyInspectionitem`,
//     method: "post",
//     data: params
//   })
// }
// 检验检测项目--删除
// export function deleteInspectionitem(params) {
//   return http({
//     url: `${preApi}/inspection/deleteInspectionitem`,
//     method: "post",
//     data: params
//   })
// }
// 检验检测项目--列表
// export function findInspectionitemListByCondition(params) {
//   return http({
//     url: `${preApi}/inspection/findInspectionitemListByCondition`,
//     method: "post",
//     data: params
//   })
// }
//获取检验设备工单
export function findAcceptanceListByCondition(params) {
  return http({
    url: `${preApi}/acceptance/findAcceptanceListByCondition`,
    method: "post",
    data: params
  })
}